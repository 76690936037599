import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Icon } from '@components/common';
import BrButton from '@components/common/BrButton';

import { normalizeStringCompound } from '@utils/string';

import ExtraFieldsList from './components/ExtraFieldsList';
import PromoRow from './components/PromoRow';
import AutoPromoRow from './components/AutoPromoRow';

import BrExpandableContainer from '../BrAnimateHeightContainer';

type ExtraField = {
  title: string;
  valueText: string;
  type?: 'discount';
};

export type PromoInfo = {
  name: string;
  discountText: string;
  descriptionText: string;
};

export type AutoPromoInfo = {
  title: string;
  description: string;
};

interface Props {
  totalText: string;
  extraFields?: ExtraField[];
  promoInfo?: PromoInfo;
  autoPromoInfo?: AutoPromoInfo;
  isExpandedDefault?: boolean;
  onRequestEnterPromoClick?(): void;
  onCancelPromoClick?(): void;
}

const BrPriceInfo: React.FunctionComponent<Props> = (props: Props) => {
  const {
    totalText,
    extraFields,
    promoInfo,
    autoPromoInfo,
    isExpandedDefault = false,
    onCancelPromoClick,
    onRequestEnterPromoClick,
  } = props;

  const { t } = useTranslation();

  const [isExpanded, setIsExpanded] = useState(isExpandedDefault);

  const toggleExpandedState = () => {
    setIsExpanded(!isExpanded);
  };

  // In this component it seems extremely complex to recreate Figma layout with given animation
  // since rows not only expand/collapse but change their containers as well.
  // So here we see Tailwind margin utilities instead of proper spacings.
  // There is still subtle layout shift on collapsing ExtraFieldsList
  return (
    <div className="flex flex-col">
      {autoPromoInfo && (
        <AutoPromoRow
          title={autoPromoInfo.title}
          description={autoPromoInfo.description}
          className={isExpanded ? 'mb-middle' : 'mb-small'}
        />
      )}
      {extraFields && (
        <BrExpandableContainer isExpanded={isExpanded}>
          <ExtraFieldsList className="mb-small" data={extraFields} />
        </BrExpandableContainer>
      )}
      {promoInfo && onCancelPromoClick && (
        <PromoRow
          name={promoInfo.name}
          discountText={promoInfo.discountText}
          descriptionText={promoInfo.descriptionText}
          onCancelPromoClick={onCancelPromoClick}
          className="mb-small"
        />
      )}
      <div className="flex items-center text-body/primary/demibold space-x-small">
        <div className="flex w-full justify-between">
          <div>{`${t('Your total')}`}</div>
          <div>{totalText}</div>
        </div>
        {Boolean(extraFields?.length) && (
          <button
            className="flex items-center w-large h-large"
            onClick={toggleExpandedState}
          >
            <Icon
              name="bottom_-_down"
              className={normalizeStringCompound([
                'transform transition-transform duration-500',
                isExpanded ? 'rotate-180' : 'rotate-0',
              ])}
            />
          </button>
        )}
      </div>

      {onRequestEnterPromoClick && (
        <div className="flex justify-center mt-small">
          <BrButton
            cmpType="link"
            text={t('I have a promo code')}
            onClick={onRequestEnterPromoClick}
          />
        </div>
      )}
    </div>
  );
};

export default BrPriceInfo;
