import { useTranslation } from 'react-i18next';

import BrAlert from '../BrAlert';
import BrOfferCard from '../BrOfferCard';

interface Props {
  id: string;
  className?: string;
  expandableContainerClassNames?: string;
  subType: string;
  carrierPromoText?: string;
  planTitle: string;
  actionText?: string;
  detailsDescriptionShort?: string;
  detailsAlertDescription?: string;
  detailsDescription?: string;
  isDetailsOpen?: boolean;
  isNonPurchasable?: boolean;
  onActionBtnClick?(id: string): void;
  onDetailsOpen?(id: string): void;
}

const BrFeaturedPlanCard = (props: Props) => {
  const {
    id,
    className,
    expandableContainerClassNames,
    subType,
    carrierPromoText,
    planTitle,
    actionText,
    detailsDescriptionShort,
    detailsAlertDescription,
    detailsDescription,
    isNonPurchasable,
    isDetailsOpen,
    onActionBtnClick,
    onDetailsOpen,
  } = props;

  const { t } = useTranslation();

  const handleOnActionButtonClick = () => {
    onActionBtnClick?.(id);
  };

  return (
    <BrOfferCard
      id={id}
      className={className}
      expandableContainerClassNames={expandableContainerClassNames}
      isFullWidthBtn
      planTypeText={subType}
      promoBadgeText={carrierPromoText}
      title={planTitle}
      descriptionShort={detailsDescriptionShort}
      isNonPurchasable={isNonPurchasable}
      onActionBtnClick={handleOnActionButtonClick}
      actionText={actionText}
      featuredText={t('FEATURED')}
      isFeatured
      isDetailsOpen={isDetailsOpen}
      onDetailsOpen={onDetailsOpen}
    >
      <div className="space-y-middle">
        {detailsAlertDescription && (
          <BrAlert
            icon="egift-solid"
            classType="warning-variant"
            header={t('Promotion details')}
            styleType="with-header"
            className="w-full"
          >
            <div className="text-body/callout/default text-on-colors/on-warning-variant whitespace-break-spaces">
              {detailsAlertDescription}
            </div>
          </BrAlert>
        )}
        <div className="text-body/callout/default text-on-colors/on-warning-variant whitespace-break-spaces">
          {detailsDescription}
        </div>
      </div>
    </BrOfferCard>
  );
};

export default BrFeaturedPlanCard;
