/* eslint-disable @typescript-eslint/indent */
import { useEffect, useRef, useState } from 'react';

import { normalizeStringCompound } from '@utils/string';
import { debounce } from 'debounce';

const DEBOUNCE_INTERVAL = 150;

export const isScrolledToBottom = (el: HTMLElement) => {
  // NOTE: scrollTop is fractional, while scrollHeight and clientHeight are
  // not, so without this Math.abs() trick then sometimes the result won't
  // work because scrollTop may not be exactly equal to el.scrollHeight -
  // el.clientHeight when scrolled to the bottom.
  // NOTE: increasing scrollEndTriggerOffset will cause bottom shadow to disappear earlier on scroll.
  // 1px is ok to sacrifice
  const scrollEndTriggerOffset = 1;
  return (
    Math.abs(el.scrollHeight - el.clientHeight - el.scrollTop) <= scrollEndTriggerOffset
  );
};

const useBrScrollShadowWrapperLogic = () => {
  const [visibleShadows, setVisibleShadows] = useState({ top: false, bottom: false });
  const [scrollShadowWrapperHeight, setScrollShadowWrapperHeight] = useState<
    number | undefined
  >();

  const scrollShadowWrapperRef = useRef<HTMLDivElement>(null);
  const scrollableContentRef = useRef<HTMLDivElement>(null);
  const scrollableNodeRef = useRef<HTMLDivElement>(null);

  const handleOnScroll = () => {
    if (scrollableNodeRef.current) {
      const { clientHeight, scrollTop, scrollHeight } = scrollableNodeRef.current;

      const isBottom = isScrolledToBottom(scrollableNodeRef.current);
      const isTop = scrollTop === 0;
      const isBetween = scrollTop > 0 && clientHeight < scrollHeight - scrollTop;

      setVisibleShadows({
        top: (isBottom || isBetween) && !(isTop && isBottom),
        bottom: !isBottom,
      });
    }
  };

  useEffect(() => {
    const handleOnResize = () => {
      setScrollShadowWrapperHeight(undefined);
      requestAnimationFrame(() => {
        // 1px is a workaround.
        // Sometimes probably due to some rounding the container is smaller than the content for 1px.
        setScrollShadowWrapperHeight(
          scrollShadowWrapperRef.current?.clientHeight
            ? scrollShadowWrapperRef.current?.clientHeight + 1
            : undefined,
        );
        handleOnScroll();
      });
    };

    const observer = new ResizeObserver(() => {
      debounce(handleOnResize, DEBOUNCE_INTERVAL);
    });

    if (scrollableContentRef?.current) {
      observer.observe(scrollableContentRef.current);
    }

    window.addEventListener('resize', handleOnResize);

    scrollableNodeRef?.current?.addEventListener('scroll', handleOnScroll, {
      passive: true,
    });

    return () => {
      if (scrollableContentRef?.current) {
        observer.unobserve(scrollableContentRef.current);
      }
      window.removeEventListener('resize', handleOnResize);

      scrollableNodeRef?.current?.removeEventListener('scroll', handleOnScroll);
    };
  }, []);

  const topShadowStyles = normalizeStringCompound([
    'br-scroll-shadow',
    'top-0',
    visibleShadows.top ? 'opacity-100' : 'opacity-0',
  ]);

  const bottomShadowStyles = normalizeStringCompound([
    'br-scroll-shadow',
    'bottom-0 rotate-180',
    visibleShadows.bottom ? 'opacity-100' : 'opacity-0',
  ]);

  return {
    bottomShadowStyles,
    topShadowStyles,
    scrollShadowWrapperHeight,
    scrollShadowWrapperRef,
    scrollableContentRef,
    scrollableNodeRef,
  };
};

export default useBrScrollShadowWrapperLogic;
